<template>
  <div role="dialog" class="modal fade fixed-end show" aria-modal="true">
    <div role="document" class="modal-dialog modal-dialog-centered modal-xl">
      <div v-if="item" class="modal-content">
        <img
          :src="item.image_url"
          width="16"
          height="9"
          alt=""
          class="screen-thumbnail"
        />

        <div class="modal-footer">
          <div class="row flex-grow-1 pt-1">
            <div class="col d-flex align-items-center">
              <p class="mb-0 black">{{ item.screen_name }} - {{ item.name }} - {{ item.taken }}</p>
            </div>
            <div class="col-auto">
              <button
                type="button"
                data-bs-dismiss="modal"
                class="btn btn-light"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CModal } from '@chameleon/chameleon-vue'

export default {
  extends: CModal,

  props: {
    item: Object
  }
}
</script>

<style scoped>
.black {
  color: #212529;
}
</style>
