<template>
  <fragment>
    <app-header title="Camera Wall">
      <template #actions>
        <button type="button" class="btn btn-primary" v-c-modal:viewSettingsDrawer>
          <i class="far fa-cog"></i> View Settings
        </button>
      </template>
    </app-header>

    <ul class="camera-wall">
      <camera-wall-list-item v-for="index in 16" :key="index" :camera="cameraForIndex(index)" @viewSettings="openDrawer" />
    </ul>

    <camera-wall-settings-drawer ref="viewSettingsDrawer" />
  </fragment>
</template>

<script>
import { helperComputed, helperMethods } from '@/vuex/modules/user/settings'
import CameraWallSettingsDrawer from './CameraWallSettingsDrawer.vue'
import CameraWallListItem from './CameraWallListItem.vue'

export default {
  components: {
    CameraWallSettingsDrawer,
    CameraWallListItem
  },

  data () {
    return {
      timer: null
    }
  },

  computed: {
    ...helperComputed
  },

  mounted: function () {
    this.timer = setInterval(() => {
      this.getSettings({ fresh: true })
    }, 300000)
  },

  created () {
    this.getSettings()
  },

  beforeDestroy () {
    this.resetSettings()
    clearInterval(this.timer)
  },

  methods: {
    ...helperMethods,

    cameraForIndex (index) {
      const arrayIndex = index - 1
      if (arrayIndex < this.settings.length) {
        return this.settings[arrayIndex]
      }

      return null
    },

    openDrawer () {
      this.$refs.viewSettingsDrawer.open()
    }
  }
}
</script>
